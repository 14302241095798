<script>
</script>

<div class="main" id="contact">
  <div class="inner-main">
    <div class="inner-main" style="flex: 4;">
      <h6 style="margin: 0 5px">You'll find me on:</h6>
      <span style="font-size: 30px;margin: 0 5px">
        <a href="https://www.instagram.com/binongkiribey/"
          ><i class="fab fa-instagram icon" /></a
        >
        <a href="https://www.linkedin.com/in/binong-kiri-bey-a38723173/"
          ><i class="fab fa-linkedin icon" /></a
        >
        <a href="https://github.com/BinongKBey"
          ><i class="fab fa-github icon" /></a
        >
      </span>
    </div>
    <h6 style="text-align: center;flex: 1;font-weight: 500;">Or</h6>
    <div class="inner-main" style="flex: 4;">
      <h6 style="text-align: center;">Contact me by</h6>
      <a href="mailto:binongkbey1@gmail.com"
        ><i class="fas fa-envelope" style="font-size: 30px;margin: 0 10px" /></a
      >
    </div>
  </div>
  <br />
  <h6 style="text-align: center;">
    Build by <span style="font-weight: 500;">Binong Kiri Bey</span>
  </h6>
</div>

<style>
  .main {
    padding: 3em;
    color: #fff;
    background: #011d44;
  }
  a {
    color: #fff;
  }
  a:hover {
    color: #999999;
  }
  .inner-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .inner-main h6 {
    font-size: 14px;
  }
</style>
