<script>
  import { onMount } from "svelte";
  import Typewriter from "typewriter-effect/dist/core";
  import Navbar from "./Navbar.svelte";
  var main;
  onMount(() => {
    var typewriter = new Typewriter(main, {
      loop: true,
      delay: 75,
      deleteSpeed: 10,
    });
    typewriter
      .pauseFor(200)
      .typeString("Hi!")
      .pauseFor(1000)
      .deleteChars(6)
      .typeString("I'm Binong")
      .pauseFor(1000)
      .deleteChars(9)
      .typeString(" build things")
      .pauseFor(1000)
      .deleteChars(14)
      .typeString("For the Web")
      .pauseFor(1000)
      .start();
  });
</script>

<div class="home">
  <Navbar />
  <div class="main-inside">
    <div class="main-row">
      <div class="left-part">
        <!-- svelte-ignore a11y-missing-content -->
        <h2 bind:this={main} />
        <hr />
        <br />
        <h5 style="color: #b3b3b3;">
          Pursuing PhD | Ex-Full Stack Web Developer
        </h5>

        <br />
        <h6 style="color: #eeeeee;">
          I'm in my first year of PhD at IIT Kanpur. My area of research is
          GPU(mainly NVIDIA GPUs), architecture, and drivers. I'm interested in
          learning new concepts and skills and improving existing ones.
        </h6>
        <!-- <br /> -->
        <h6 style="color: #eeeeee;">
          I also work on Web Development, App Development and Software
          Development. I also worked as a freelancer.
        </h6>
      </div>
      <div class="right-part">
        <img class="circle image" src="./assets/img/me.jpeg" alt="me" />
        <div class="icon-set">
          <h6 style="text-align: center;">You can find me on:</h6>
          <span>
            <a href="https://www.instagram.com/binongkiribey/"
              ><i class="fab fa-instagram icon" /></a
            >
            <a href="https://www.linkedin.com/in/binong-kiri-bey-a38723173/"
              ><i class="fab fa-linkedin icon" /></a
            >
            <a href="https://github.com/BinongKBey"
              ><i class="fab fa-github icon" /></a
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  a {
    color: #fff;
  }
  a:hover {
    color: #999999;
  }
  .icon {
    margin: 10px;
    font-size: 50px;
  }
  .left-part {
    max-width: 800px;
    text-align: left;
    padding: 0 2em;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  .left-part h6 {
    line-height: 1.5;
    font-size: 1.2rem;
  }
  .right-part {
    display: flex;
    /* margin: auto; */
    /* max-width: 400px; */
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  .right-part,
  .left-part {
    margin-top: 2em;
  }
  .main-inside {
    width: 100%;
    margin: auto;
  }
  .home {
    background: #011d44;
    /* padding: 2em; */
    flex-direction: column;
    color: #fff;
    display: flex;
    min-height: 100vh;
  }
  .image {
    width: 200px;
    margin-bottom: 20px;
  }
  .main-row {
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
  }
  @media (max-width: 833px) {
    .icon-set {
      visibility: hidden;
      z-index: -10;
      position: absolute;
    }
  }
</style>
