<script>
  import SingleProject from "./SingleProject.svelte";
  let projects = [
    {
      name: "On Chat",
      desc: "An online group chat web application, where you can create your own private temporary chat rooms with password.",
      img: "/assets/projects/on-chat.jpg",
      link: "http://bkb-on-chat.herokuapp.com/",
    },
    {
      name: "Town of Stories",
      desc: "An place to share your stories or poems with everyone on the web.",
      img: "/assets/projects/town-of-stories.jpg",
      link: "http://townofstories.herokuapp.com/",
    },
    {
      name: "Creative Town",
      desc: "A place to share your online web novels with everyone on the web.",
      img: "/assets/projects/project-ct.jpg",
      link: "https://project-ct.netlify.app/",
    },
    // {
    //   name: "Medical Website Demo",
    //   desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure recusandae veniam nobis asperiores modi aperiam fugit, eum labore incidunt dolorum ratione debitis deserunt tempora vitae minima placeat vero possimus. Minus veritatis, ea officia reprehenderit deserunt possimus provident accusantium neque nisi nostrum perferendis beatae quas alias iste pariatur veniam, rerum numquam.",
    //   img: "/assets/projects/on-chat.png",
    // },
  ];
  let selected = projects[0];
  const selectPj = (name, desc, img, link) => {
    selected = {
      name,
      desc,
      img,
      link,
    };
  };
</script>

<div class="main" id="projects">
  <div class="container">
    <h2 class="title">My Projects</h2>
    <br />
    <h5 class="title-subtext1">
      Please refer to my github for my projects:
      <a href="https://github.com/BinongKBey">github</a>
    </h5>
    <!-- <h5 class="title-subtext1">Here are a few projects that I have made:</h5> -->
    <!-- <p class="title-subtext2">(Click on the buttons to preview the projects)</p>
    <div class="project-btn-row">
      {#each projects as pj}
        <button
          class="waves-effect blue darken-3 btn pj-btn"
          on:click={() => selectPj(pj.name, pj.desc, pj.img, pj.link)}
          >{pj.name}</button
        >
      {/each}
    </div>
    <SingleProject
      name={selected.name}
      desc={selected.desc}
      img={selected.img}
      link={selected.link}
    /> -->
  </div>
</div>

<style>
  .main {
    background: #fff;
    padding: 1em;
    color: #647997;
    min-height: 100vh;
  }
  .title {
    color: #011d44;
    text-align: center;
  }
  .pj-btn {
    margin: 2px 2px;
  }
  .project-btn-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title-subtext1 {
    text-align: center;
  }
  .title-subtext2 {
    text-align: center;
  }
</style>
