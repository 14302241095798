<script>
</script>

<nav>
  <div class="nav-wrapper">
    <!-- <a href="/" class="brand-logo">Portfolio</a> -->
    <a href="#!" data-target="slide-out" class="sidenav-trigger"
      ><i class="material-icons">menu</i></a
    >
    <ul class="right hide-on-med-and-down">
      <li><a href="#about-me">About Me</a></li>
      <li><a href="#skills">My Skills</a></li>
      <li><a href="#projects">My Projects</a></li>
      <li><a href="#contact">Contact Me</a></li>
    </ul>
  </div>

  <ul id="slide-out" class="sidenav">
    <li><a href="#!">Binong's Portfolio</a></li>
    <li><div class="divider" /></li>
    <li><a href="#!" class="subheader">Quick Links</a></li>
    <li><a class="waves-effect" href="#about-me">About Me</a></li>
    <li><a class="waves-effect" href="#skills">My Skills</a></li>
    <li><a class="waves-effect" href="#projects">My Projects</a></li>
    <li><a class="waves-effect" href="#contact">Contact Me</a></li>
  </ul>
</nav>

<style>
  nav {
    background: #011d44;
    padding: 0 2em;
  }
  @media only screen and (max-width: 992px) {
    nav {
      padding: 0;
    }
  }
</style>
