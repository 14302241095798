<script>
  // import Navbar from "./components/Navbar.svelte";
  import Home from "./components/Home.svelte";
  import AboutMe from "./components/AboutMe.svelte";
  import Skills from "./components/Skills.svelte";
  import Projects from "./components/Projects.svelte";
  import Footer from "./components/Footer.svelte";
</script>

<main>
  <Home />
  <AboutMe />
  <Skills />
  <Projects />
  <Footer />
</main>

<style>
</style>
