<script>
</script>

<div class="skills" id="skills">
  <div class="container">
    <h2>My Skills</h2>
    <br />
    <!-- <h5>Here are a few technologies I've been working with recently:</h5> -->
    <h5>Here are a few technologies I'm familiar with:</h5>
    <br />
    <div class="image-row">
      <div>
        <img src="./assets/img/cpp.png" alt="cpp" />
        <h6>C++</h6>
      </div>
      <div>
        <img src="./assets/img/mongodb.png" alt="mongodb" />
        <h6>MongoDB</h6>
      </div>
      <div>
        <img src="./assets/img/react.png" alt="react" />
        <h6>React</h6>
      </div>
      <div>
        <img src="./assets/img/javascript.png" alt="javascript" />
        <h6>JavaScript</h6>
      </div>
      <div>
        <img src="./assets/img/nodejs.png" alt="nodejs" />
        <h6>NodeJs</h6>
      </div>
      <div>
        <img src="./assets/img/python.png" alt="python" />
        <h6>Python</h6>
      </div>
      <div>
        <img src="./assets/img/django.png" alt="django" />
        <h6>Django</h6>
      </div>
      <div>
        <img src="./assets/img/svelte.png" alt="svelte" />
        <h6>Svelte</h6>
      </div>
      <div>
        <img src="./assets/img/flask.png" alt="flask" />
        <h6>Flask</h6>
      </div>
      <div>
        <img src="./assets/img/postgres.png" alt="postgresql" />
        <h6>PostgreSQL</h6>
      </div>
      <div>
        <img src="./assets/img/cuda-cpp.png" alt="cudacpp" />
        <h6>Cuda C/CPP</h6>
      </div>
      <div>
        <img src="./assets/img/linux.png" alt="linux" />
        <h6>Linux</h6>
      </div>
    </div>
  </div>
</div>

<style>
  .skills h2,
  h5 {
    text-align: center;
  }
  .skills {
    background: #011d44;
    padding: 1em;
    color: #fff;
    min-height: 100vh;
  }
  .image-row {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .image-row img {
    width: 100px;
    margin: 3px;
  }
  .image-row h6 {
    text-align: center;
  }
  .image-row div {
    height: 170px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
