<div class="main" id="about-me">
  <div class="container">
    <h2 class="gbl-main-title">About Me</h2>
    <br />
    <div class="college-logo-container">
      <img src="./assets/img/iitk.png" class="iitk-logo" alt="iitk logo" />
      <!-- <img src="./assets/img/aec-logo.png" class="aec-logo" alt="aec logo" /> -->
    </div>
    <h6>
      I am a first-year Computer Science and Engineering <b>PhD</b> student at
      The
      <b>Indian Institute of Technology Kanpur (IITK)</b>, India. I am
      researching GPU architectures and will soon delve into GPU drivers. I
      completed my <b>B.Tech</b>
      in Computer Science and Engineering (CSE) at
      <b>Assam Engineering College</b>. During B.Tech, I delved into Web
      Development and was fascinated by it. Since then, I have honed my skills
      in Web Development, App Development and other programming fields. I find
      immense joy in crafting seamless user experiences and innovative
      solutions. Alongside my studies, I've had the privilege of interning with
      dynamic startups such as TecMeadows, Zephyr Solutions and Decodr
      Technologies, where I gained valuable industry experience and contributed
      to real-world projects. As an aspiring researcher and developer, I am
      eager to merge my academic knowledge with practical expertise to make a
      meaningful technological impact. I aim to create efficient hardware
      systems while crafting captivating digital experiences.
    </h6>
    <!-- <br /> -->
  </div>
</div>

<style>
  .main {
    min-height: 100vh;
    padding: 1em;
    background: #fafafa;
    color: #011d44;
    margin-bottom: 3em;
  }
  .main h6 {
    line-height: 1.6;
    font-weight: 500;
    font-size: 1.3rem;
  }
  /* .aec-logo {
    width: 150px;
    display: block;
    margin: 0 20px;
  } */
  .iitk-logo {
    width: 150px;
    display: block;
    margin: 0 20px 20px 20px;
  }
  .college-logo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
</style>
